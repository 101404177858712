import React from 'react'
const BannerImage = (props) => {
    return (!props.img)
      ? null
      : (
        <section id="banner" className="major" style={{
            backgroundImage:`url(${props.img})`
        }}>
        </section>
    )
}

export default BannerImage
