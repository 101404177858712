import React from 'react'
import Layout from '../components/layout'
import Banner from '../components/BannerImage'
import tablet from '../assets/images/tablet.png'
import widget from '../assets/images/Widget.png'
import fbcmanage from '../assets/images/managereviews.png'
import kghdemo from '../assets/images/kgh-demo.png'
import fbcBanner from '../assets/images/fbc-banner.jpg'
import ketteringbanner from '../assets/images/kghbanner.jpg'

const FeedbackCentre = props => (
    <Layout pageTitle="Feedback Centre" metaDescription="Feedback Centre" metaKeywords={['feedback', 'feedback centre']}>
        <Banner img={fbcBanner}/>
        <div id="main" >
            <div className="inner">
                <h2 id="content">What is Feedback Centre?</h2>
               <p>
                   Customer experience is more than just a trend, it’s an essential part of any business or service, that’s why we’ve put feedback at the forefront of our product range. Feedback Centre allows you to collect quick fire ratings or in-depth survey entries to help transform the quality of the services you provide.
               </p>
                <p>
                   Built on a web-based platform, you can be on the frontline collecting customer stories without leaving your seat. Designed by a team of user experience specialists, our Feedback Centre makes leaving feedback easy and enjoyable.
               </p>
            </div>
        </div>
        <div id="main" className="alt">
            <div className="inner">
                <h2 id="content">Why Feedback Centre?</h2>
                <section id="one">
                    <div className="grid-wrapper">
                        <div className="col-4">
                            <h3>Improve the visibility of data and gather the feedback you need, when you need it.</h3>
                            <p>Feedback Centre enables your organisation to offer stakeholders an inclusive way to share information. Our platform is utilised by organisations across many sectors. </p><p>They all have the same aim, to deliver better customer service. Customer responses are available in real-time, enabling swift responses and information that can be obtained organisation-wide.</p>
                            <span className="image fit">
                                <img src={tablet} alt="Feedback Centre Widget"/>
                            </span>
                        </div>
                        <div className="col-4">
                            <h3>Act on information you can trust. Understand feedback from your customers.</h3>
                            <p>All of the data you receive from the public is securely stored in your Feedback and Moderation System.</p>
                            <p>This innovative tool offers many advantages to your team including enhanced marketing awareness, increased data and opportunities to build new commercial relationships. </p>
                            <span className="image fit">
                                <img src={fbcmanage} alt="Feedback Centre Widget"/>
                            </span>
                        </div>
                        <div className="col-4">
                            <h3>Tailor it to your needs, your brand and your customers.</h3>
                            <p>The Feedback Centre works by using a set of different parameters and modules, meaning you can add or remove the elements you need as well as tailoring the front end to match your branding. </p>
                            <p>A ‘TripAdvisor’ style partner widget can also be provided to any of your health or social care partners. They can be created and fully managed by you through the Feedback Centre platform.</p>
                            <span className="image fit">
                                <img src={widget} alt="Feedback Centre Widget"/>
                            </span>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <Banner img={ketteringbanner}/>

        <div id="main" className="alt">
            <div className="inner">
                <section id="two">
                    <header className="major">
                        <h3>Case Study: Kettering General Hospital</h3>
                    </header>
                    <div className="grid-wrapper">
                        <div className="col-8">
                         <p>Kettering General Hospital went live with its brand new Feedback Centre at the end of 2018.
                             Each year KGH looks after over 40,000 inpatients, 38,000 day-case patients, they have 235,000 outpatients contacts, 83,000 visitors to their A&E department and over 3,600 women give birth within the Rockingham Wing so having a clear picture of the care patients and their families are experiencing is essential for the patient experience team.</p>

                            <p>    Working alongside our Product Director, James Allan and his passionate team helped mould Kettering General Hospital’s implementation of the Feedback Centre and the reporting tools that came with it. Kiosks can be found in the A&E waiting room, main outpatients, main reception, Rockingham Wing and the Treatment Centre, with more tablets to be added in the coming months. </p>

                            <p> The team don’t just focus on patient feedback, they also use the Feedback Centre for staff surveys and friends and family tests to asses current trends and any key areas that need working on. White Bear are extremely excited to be continuing our work with Kettering General Hospital, with a lot more features coming in the near future! </p>
                          </div>
                        <div className="col-4">
                            <span className="image fit">
                                <img src={kghdemo} alt="Kettering General Hospital Feedback Centre Platform" />
                            </span>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </Layout>
)

export default FeedbackCentre